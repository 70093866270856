import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ec5659f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-0" }
const _hoisted_2 = {
  key: 0,
  class: "p-3"
}
const _hoisted_3 = { class: "m-0" }
const _hoisted_4 = {
  key: 0,
  class: "p-3"
}
const _hoisted_5 = {
  key: 1,
  class: "row justify-content-center"
}
const _hoisted_6 = { class: "card col-12 col-lg-8" }
const _hoisted_7 = { class: "page-heading" }
const _hoisted_8 = { class: "course-title" }
const _hoisted_9 = { class: "card-body mx-auto" }
const _hoisted_10 = { class: "size-limit-caption" }
const _hoisted_11 = { class: "size-limit-caption" }
const _hoisted_12 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!
  const _component_LuSpinner = _resolveComponent("LuSpinner")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_DetailsRow = _resolveComponent("DetailsRow")!
  const _component_FileUpload = _resolveComponent("FileUpload")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Modal, {
      ref: "success-modal",
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToNoteTakingRequest()))
    }, _createSlots({
      default: _withCtx(() => [
        (_ctx.notes !== null)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t("share-notes.popup-success.message")), 1))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (_ctx.notes !== null)
        ? {
            name: "header",
            fn: _withCtx(() => [
              _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("share-notes.popup-success.title", {
            name: _ctx.notes.noteTakerFirstName,
          })), 1)
            ])
          }
        : undefined
    ]), 1536),
    _createVNode(_component_Modal, { ref: "error-modal" }, _createSlots({
      default: _withCtx(() => [
        (_ctx.notes !== null)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t("shared.popup-error.message")), 1))
          : _createCommentVNode("", true)
      ]),
      _: 2
    }, [
      (_ctx.notes !== null)
        ? {
            name: "header",
            fn: _withCtx(() => [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("shared.popup-error.title", {
            name: _ctx.notes.noteTakerFirstName,
          })), 1)
            ])
          }
        : undefined
    ]), 1536),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LuSpinner, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.notes)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.$t("share-notes.page-title")) + ": " + _toDisplayString(_ctx.notes.courseCode) + " " + _toDisplayString(_ctx.notes.courseTerm), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.courseTitle), 1),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_DetailsRow, {
                caption: "share-notes.note-taking-date",
                required: true
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DatePicker, {
                    "aria-label": "Date Picker",
                    value: _ctx.notesDate,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.notesDate) = $event)),
                    required: true,
                    clearable: false,
                    enableTimePicker: false,
                    format: _ctx.dateFormat,
                    disabledDates: _ctx.disabledDates,
                    disabledWeekDays: _ctx.disabledWeekDays
                  }, null, 8, ["value", "format", "disabledDates", "disabledWeekDays"])
                ]),
                _: 1
              }),
              _createVNode(_component_DetailsRow, {
                caption: "share-notes.note-taking-time",
                required: true
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DatePicker, {
                    "aria-label": "Time Picker",
                    value: _ctx.notesTime,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.notesTime) = $event)),
                    required: true,
                    clearable: false,
                    timePicker: true,
                    format: _ctx.timeFormat,
                    hideInputIcon: true
                  }, null, 8, ["value", "format"])
                ]),
                _: 1
              }),
              _createVNode(_component_DetailsRow, {
                caption: "share-notes.select-file",
                required: true
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FileUpload, {
                    ref: "fileUpload",
                    maxSizeInMb: _ctx.maxSizeInMb,
                    permittedFileTypes: _ctx.permittedFileTypes,
                    fileIsSelected: _ctx.fileIsSelected,
                    "onUpdate:fileIsSelected": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.fileIsSelected) = $event)),
                    fileIsReady: _ctx.fileIsReady,
                    "onUpdate:fileIsReady": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.fileIsReady) = $event)),
                    file: _ctx.file,
                    "onUpdate:file": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.file) = $event))
                  }, null, 8, ["maxSizeInMb", "permittedFileTypes", "fileIsSelected", "fileIsReady", "file"]),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(`${this.$t("file-upload.permitted-file-types")}: ${
                this.permittedFileTypes
              }`), 1),
                  _createElementVNode("div", _hoisted_11, _toDisplayString(`${this.$t("file-upload.max-file-size")}: ${this.maxSizeInMb} MB`), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_DetailsRow, { class: "button-row" }, {
                default: _withCtx(() => [
                  _createElementVNode("button", {
                    id: "cancelBtn",
                    type: "button",
                    class: "btn btn-outline-primary mb-3",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.goToNoteTakingRequest()))
                  }, _toDisplayString(_ctx.$t("shared.cancel")), 1),
                  _createElementVNode("button", {
                    id: "uploadBtn",
                    type: "button",
                    class: "btn btn-primary mb-3",
                    disabled: !_ctx.fileIsSelected,
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onShareButtonClicked()))
                  }, _toDisplayString(_ctx.$t("share-notes.share-notes")), 9, _hoisted_12)
                ]),
                _: 1
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}